import React, { useState } from "react";
import styled from "styled-components";
import colors from "../constants/colors";
import useWindowDimensions from "../constants/size";
import InputText from "../components/InputText";

const Contact = () => {
	const { width, height } = useWindowDimensions();
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	return (
		<div
			id="#element"
			style={{ position: "relative", zIndex: 1 }}
		>
			<Container
				width={width}
				height={height}
			>
				<HeadContainer>
					<TitleContainer>
						<GradientText>Get in Touch</GradientText>
					</TitleContainer>
					{/* <Description width={width}>Services provide for you.</Description> */}
				</HeadContainer>
				<PromotionContainer>
					<PromotionBlock>
						{/* <PromotionImage width={width} alt="vid" src={require("../assets/images/contact-image.jpg")} /> */}
						<UserContainer>
							<Title>Say Hello To</Title>
							<Name>Zubair Shafqat</Name>
							<SubDescription>Freelancer Graphic Designer</SubDescription>
							<ContactContainer>
								<ContactInfo>
									<ContactTitle>Phone</ContactTitle>
									<ContactLink href="tel:+923007015710">
										+92 3007015710
									</ContactLink>
								</ContactInfo>
								<ContactInfo>
									<ContactTitle>Email</ContactTitle>
									<ContactLink href="mailto:imzubairvirk@gmail.com">
										imzubairvirk@gmail.com
									</ContactLink>
								</ContactInfo>
							</ContactContainer>
						</UserContainer>
					</PromotionBlock>
					<PromotionBlock style={{ marginLeft: 30 }}>
						<InputText
							// title={"Your Name"}
							value={name}
							onChange={(text) => {
								setName(text.target.value);
							}}
							placeholder={"Your Name"}
							inputType={"text"}
						/>
						<InputText
							// title={"Contact Email"}
							value={email}
							onChange={(text) => {
								setEmail(text.target.value);
							}}
							placeholder={"Email Address"}
							inputType={"email"}
						/>
						<InputText
							// title={"Company Name"}
							value={phone}
							onChange={(text) => {
								setPhone(text.target.value);
							}}
							placeholder={"Phone Number"}
							inputType={"text"}
						/>
						<InputText
							// title={"Company Name"}
							value={subject}
							onChange={(text) => {
								setSubject(text.target.value);
							}}
							placeholder={"Subject"}
							inputType={"text"}
						/>
						<InputText
							// title={"Your Message"}
							value={message}
							textArea
							onChange={(text) => {
								setMessage(text.target.value);
							}}
							placeholder={"Type your message..."}
							inputType={"text"}
							inputContainerStyle={{ width: "100%" }}
							inputStyle={{ height: 100, margin: 0 }}
						/>
						<Btn onClick={() => {}}>Submit</Btn>
					</PromotionBlock>
				</PromotionContainer>
			</Container>
		</div>
	);
};

export default Contact;

const Container = styled.div`
	margin-left: ${({ width }) => width * 0.05}px;
	margin-right: ${({ width }) => width * 0.05}px;
	/* height: ${({ height }) => height * 0.95}px; */
	padding-bottom: 100px;
	/* border-bottom: 1px solid grey; */
`;

const HeadContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const GradientText = styled.p`
	font-size: 20px;
	background: -webkit-linear-gradient(
		45deg,
		${colors.primary},
		${colors.primaryLight}
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin: 0px;
	font-weight: bold;
`;

const TitleContainer = styled.div`
	background: ${colors.greyDark};
	padding: 10px;
	margin: 0px;
	border-radius: 5px;
`;

const Description = styled.p`
	font-size: ${({ width }) => width * 0.03}px;
	font-weight: bold;
	margin-top: 10px;
`;

// const InnerContainer = styled.div`
//     display: flex;
//     justify-content: space-between;
// `

const PromotionContainer = styled.div`
	display: flex;
	width: 100%;
	margin-top: 100px;
	@media (max-width: 968px) {
		display: block;
	}
`;

const PromotionBlock = styled.div`
	width: 50%;
	position: relative;
	@media (max-width: 968px) {
		width: 80%;
	}
`;

const PromotionImage = styled.img`
	width: 100%;
	border-radius: 10px;
`;

const Btn = styled.button`
	border: 0px;
	background: ${colors.gradientPrimary};
	position: absolute;
	left: 0;
	color: ${colors.white};
	width: 150px;
	padding: 10px 30px;
	font-size: 18px;
	height: 50px;
	margin-top: 30px;
	border-radius: 5px;
`;

const UserContainer = styled.div`
	margin-bottom: 3rem;
`;

const Title = styled.h1`
	font-size: 2.5rem;
	font-weight: bold;
	color: #333;
`;

const Name = styled.h2`
	color: ${colors.primary};
	font-size: 4rem;
	font-weight: 600;
	/* color: #fff; */
	margin-top: 16px;
`;

const SubDescription = styled.p`
	color: ${colors.grey};
	font-size: 1.5rem;
	margin-top: -9%;
`;

const ContactContainer = styled.div`
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15%;

	@media (min-width: 768px) {
		flex-direction: row;
		justify-content: center;
	}
`;

const ContactInfo = styled.div`
	text-align: center;
`;

const ContactTitle = styled.h3`
	font-size: 2rem;
	font-weight: bold;
	margin-bottom: 5px;
`;

const ContactLink = styled.a`
	color: #007bff;
	font-size: 1.5rem;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`;
